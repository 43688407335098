import React from "react";
import { graphql } from "gatsby"
import SEO from "../components/seo"


const Contact = ({data}) => {
	const siteTitle = data.site.siteMetadata.title

	return (
		<div className="page-wrapper">
            <SEO 
                title="Privacy policy" 
                description="Privacy policy for use at Subflipper"
                pagePath="/privacy"
            />

			<h1>Privacy policy</h1>
            <div className="divider-hr">
                <span className="divider-span"></span>
            </div>

            <div className="terms-desc">
			<p>
                Welcome to our Privacy Policy.
             </p>
             <p>
                Your privacy is critically important to us.

                It is Subflipper.com’s policy to respect your privacy regarding any information we may collect while operating our website. 
                We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. 
                We have adopted this privacy policy ("Privacy policy") to explain what information may be collected on our Website, 
                how we use this information, and under what circumstances we may disclose the information to third parties. 
                This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.
            </p>
            <p>
                This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing 
                your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.
            </p>
            <p>
                We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.
            </p>

                <h5>Definitions</h5>

                <b>Service</b>
                <p>Service means the https://subflipper.com website.</p>

                <b>Personal Data</b>
                <p>Personal Data means data about a living individual who can be identified from those data 
                (or from those and other information either in our possession or likely to come into our possession).</p>

                <b>Usage Data</b>
                <p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself 
                (for example, the duration of a page visit).</p>

                <h5>Information Collection and Use</h5>
                We collect several different types of information for various purposes to provide and improve our Service to you.

                <h6>Types of Data Collected</h6>
                <b>Personal Data</b>
                <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or 
                identify you (“Personal Data”). Personally identifiable information may include, but is not limited to: Email address, First name and last name,
                Cookies and Usage Data</p>

                <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. 
                You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email 
                we send or by contacting us.</p>

                <b>Usage Data</b>
                <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a 
                mobile device (“Usage Data”). This Usage Data may include information such as your computer’s 
                Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, 
                the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

                <p>When you access the Service by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, 
                your mobile device unique ID, the IP address of your mobile device, your mobile operating system, 
                the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>

                <b>Use of Data</b>
                <p>Subflipper.com uses the collected data for various purposes:</p>
                    <ul style={{textAlign: 'left'}}>
                        <li>To provide and maintain our Service</li>

                        <li>To notify you about changes to our Service</li>

                        <li>To allow you to participate in interactive features of our Service when you choose to do so</li>

                        <li>To provide customer support</li>

                        <li>To gather analysis or valuable information so that we can improve our Service</li>

                        <li>To monitor the usage of our Service</li>

                        <li>To detect, prevent and address technical issues</li>

                        <li>To provide you with news, special offers and general information about other goods, services and events which we 
                        offer that are similar to those that you have already purchased or enquired about unless you have 
                        opted not to receive such information</li>
                    </ul>
                
                <h5>Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)</h5>
                <p>Subflipper.com may process your Personal Data because:</p>
                <ul style={{textAlign: 'left'}}>
                    <li>We need to perform a contract with you</li>

                    <li>You have given us permission to do so</li>

                    <li>The processing is in our legitimate interests and it’s not overridden by your rights</li>

                    <li>To comply with the law</li>
                </ul>

                <h5>Retention of Data</h5>
                <p>Subflipper.com will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. 
                We will onlu retain and use your Personal Data to the extent necessary to comply with our legal obligations 
                (for example, if we are required to retain your data to comply with applicable laws), resolve technical issues, to provide this service, 
                resolve disputes, and enforce our legal agreements and policies.</p>

                <p>We perform regular checks of the data to ensure that data which is not in use anymore is removed, this is to prevent retention of data longer than 
                necessary. These checks are performed once a year at the least.</p>

                <h5>Transfer of Data</h5>
                <p>Your information, including Personal Data, may be transferred to — and maintained on —
                computers located outside of your state, province, country or other governmental jurisdiction 
                where the data protection laws may differ than those from your jurisdiction. 
                Your consent to this Privacy Policy followed by your submission of such information represents your 
                agreement to that transfer. Subflipper.com will take all steps reasonably necessary to ensure 
                that your data is treated securely and in accordance with this Privacy Policy and no transfer 
                of your Personal Data will take place to an organization or a country unless there 
                are adequate controls in place including the security of your data and other personal information.</p> 

                <h5>Legal Requirements</h5>
                <p>Subflipper.com may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul style={{textAlign: 'left'}}>
                    <li>To comply with a legal obligation</li>
                    <li>To protect and defend the rights or property of Subflipper.com.</li>
                    <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li>To protect the personal safety of users of the Service or the public</li>
                    <li>To protect against legal liability</li>
                </ul>
                
                <h5>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h5>
                <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. 
                Subflipper.com aims to take reasonable steps to allow you to correct, amend, delete, or limit the use 
                of your Personal Data.</p>

                <p>If you wish to be informed what Personal Data we hold about you and if you want it to 
                be removed from our systems, please contact us.</p>

                <p>In certain circumstances, you have the following data protection rights:</p>
                <p>The right to access, update or to delete the information we have on you. 
                Whenever made possible, you can access, update or request deletion of your Personal Data 
                directly within your account settings section. If you are unable to perform these actions yourself,
                please contact us to assist you.</p>

                <p>The right of rectification. You have the right to have your information rectified if that 
                information is inaccurate or incomplete.</p>

                <p>The right to object. You have the right to object to our processing of your Personal Data.</p>

                <p>The right of restriction. You have the right to request that we restrict the processing of
                your personal information.</p>

                <p>The right to data portability. You have the right to be provided with a copy of the information 
                we have on you in a structured, machine-readable and commonly used format.</p>

                <p>The right to withdraw consent. You also have the right to withdraw your consent at any time where 
                Subflipper.com relied on your consent to process your personal information.</p>

                <p>Please note that we may ask you to verify your identity before responding to such requests.</p>

                <p>You have the right to complain to a Data Protection Authority about our collection and use of 
                your Personal Data. For more information, please contact your local data protection authority
                in the European Economic Area (EEA).</p>

                <h5>Service Providers</h5>
                <p>We may employ third party companies and individuals to facilitate our Service (“Service Providers”),
                to provide the Service on our behalf, to perform Service-related services or to assist us in 
                analyzing how our Service is used.</p>
                <p>These third parties have access to your Personal Data only to perform these tasks on our behalf 
                and are obligated not to disclose or use it for any other purpose.</p>

                <h5>Analytics</h5>
                    <p>We may use third-party Service Providers to monitor and analyze the use of our Service. One such service
                    is Google Analytics. Google Analytics is a web analytics service offered by Google that tracks and 
                    reports website traffic. Google uses the data collected to track and monitor the use of our Service. 
                    This data is shared with other Google services. Google may use the collected data to contextualize 
                    and personalize the ads of its own advertising network.
                    For more information on the privacy practices of Google, 
                    please visit the Google Privacy & Terms web page: https://policies.google.com/privacy?hl=en</p>
                
                
                
                <h5>Website Visitors</h5>
                    <p>Like most website operators, Subflipper.com collects non-personally-identifying information 
                    of the sort that web browsers and servers typically make available, 
                    such as the browser type, language preference, referring site, 
                    and the date and time of each visitor request. 
                    Subflipper.com’s purpose in collecting non-personally identifying information is to 
                    better understand how Subflipper.com’s visitors use its website. 
                    From time to time, Subflipper.com may release non-personally-identifying information in the 
                    aggregate, e.g., by publishing a report on trends in the usage of its website.</p>
                    <p>Subflipper.com also collects potentially personally-identifying information like 
                    Internet Protocol (IP) addresses. Subflipper.com only discloses logged in user and commenter 
                    IP addresses under the same circumstances that it uses and discloses personally-identifying 
                    information as described below.</p>
                
                <h5>Children's Privacy</h5>
                    <p>Our Service does not address anyone under the age of 18.</p>
                    <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. 
                    If you are a parent or guardian and you are aware that your child has provided us with 
                    Personal Data, please contact us. If we become aware that we have collected Personal Data 
                    from children without verification of parental consent, we take steps to remove that information 
                    from our servers.</p>

                
                <h5>Security</h5>
                    <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

                    <p>“Do Not Track” Signals Under California Online Privacy Protection Act (CalOPPA):</p>
                    <p>We do not support Do Not Track (“DNT”). Do Not Track is a preference you can set in your web 
                    browser to inform websites that you do not want to be tracked.</p>

                <h5>Advertisements</h5>
                    <p>Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Subflipper.com and does not cover the use of cookies by any advertisers.</p>
                

                <h5>Links To Other Sites</h5>
                    <p>Our Service may contain links to external sites that are not operated by us. If you click 
                    on a third party link, you will be directed to that third party's site. We strongly advise you 
                    to review the Privacy Policy and terms and conditions of every site you visit.</p>
                    <p>We have no control over, and assume no responsibility for the content, 
                    privacy policies or practices of any third party sites, products or services.</p>
                
                
                <h5>Aggregated Statistics</h5>
                    <p>Subflipper.com may collect statistics about the behavior of visitors to its website. Subflipper.com may display this information publicly or provide it to others. However, Subflipper.com does not disclose your personally-identifying information.</p>
                
                
                <h5>Tracking and Cookies</h5>
                    <p>To enrich and perfect your online experience, Subflipper.com uses "Cookies", 
                    similar technologies and services provided by others to display personalized content, 
                    appropriate advertising and store your preferences on your computer.</p>
                    <p>A cookie is a string of information that a website stores on a visitor’s computer, 
                    and that the visitor’s browser provides to the website each time the visitor returns. 
                    Subflipper.com uses cookies to help Subflipper.com identify and track visitors, 
                    their usage of the Service, and their website access preferences. 
                    Subflipper.com visitors who do not wish to have cookies placed on their computers should set 
                    their browsers to refuse cookies before using Subflipper.com’s websites, with the drawback that certain features of Subflipper.com’s websites may not function properly without the aid of cookies.</p>
                    <p>By continuing to navigate our website without changing your cookie settings, 
                    you hereby acknowledge and agree to Subflipper.com's use of cookies.</p>
                    
                    <p>Examples of Cookies we use:
                        <i>Session Cookies. We use Session Cookies to operate our Service.</i>
                        <i>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</i>
                        <i>Security Cookies. We use Security Cookies for security purposes</i>
                    </p>
                    
                <h5>Privacy Policy Changes</h5>
                    <p>Although most changes are likely to be minor, Subflipper.com may change its Privacy Policy 
                    from time to time, and in Subflipper.com’s sole discretion. Subflipper.com encourages visitors to 
                    frequently check this page for any changes to its Privacy Policy. Your continued use of this site 
                    after any change in this Privacy Policy will constitute your acceptance of such change.</p>
                
                <h5>Contact Us</h5>
                    <p>If you have any questions about this Privacy Policy, please contact us:</p>
                    <p>By email: support@subflipper.com</p>
                    <p>Or by seeing this page: <a href="/contact">https://subflipper.com/contact</a></p>

            </div>
		</div>
	)
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`